@use "base.scss";
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200&family=Fira+Sans:wght@900&family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: fit-content;
  transition: 0.5s;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
}

.App {
    min-height: 100vh;
    position: relative;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1px;
    color: map-get(base.$themes, "soft-black");

    // Containers
    .page {
        min-height: calc(100vh - 194.5px);
    }
}

.modal-dialog {
    max-width: 80% !important;

    .modal-header {
        height: 64px;
        border-bottom: none;
    }

    .ingredients, .menu-items {
        width: fit-content;
    }

    .checkboxes {
        display: flex;
    }

    .multi-input {
        display: flex;
        justify-content: space-around;
    }

    .modal-body {
        padding: 0 10%;
    }
}

.App, .modal {
    font-size: 14px;

    // Headers
    h2.title {
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        margin-top: 24px;
        font-weight: 800;
        font-size: 1.5rem;
    }

    h3.title {
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        margin-top: 24px;
        font-weight: 600;
        font-size: 1.2rem;
    }

    h4.title {
        text-align: left;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 0.9rem;
    }

    // Buttons
    .button {
        display: flex;
        width: fit-content;
        border-radius: 8px;
        border: none;
        height: 32px;
        align-items: center;
        margin: 12px;
        padding: 0 12px;

        &:focus {
            box-shadow: none;
        }

        &.create {
            border-radius: 50%;
            height: 32px;
            width: 32px;
            padding: 0;

        }

        &.submit {
            background-color: map-get(base.$themes, "green") !important;
            color: map-get(base.$themes, "off-white") !important;
        }

        &.delete {
            background-color: map-get(base.$themes, "red") !important;
            color: map-get(base.$themes, "off-white") !important;
        }
    }


    .btn-close:focus {
        box-shadow: none !important;
    }

    // Links
    .link-container {
        display: flex;
        justify-content: center;

    }

    .link {
        background-color: transparent;
        border: none;
        text-decoration: underline;
        color: inherit;
        text-align: center;
        font-weight: 600;
    }

    // Forms

    form {
        display: flex;
        flex-direction: column;
        margin: 24px auto;
        align-items: center;
        width: 100%;

        & > div {
            width: 100%;
        }
    
        label {
            display: inherit;
            width: 100%;
            margin: 0;
        }

        // Inputs
        input {
            border: none;
            border-radius: 8px;
            width: 100%;
            height: 32px;
            padding: 0 12px;
        
            &:focus-visible {
                border: none;
            }
        }


        ul {
            margin: 0;
        }

        .feedback {
            align-items: flex-start;
            width: 100%;
            height: fit-content;
        }

    }
    
    // Inputs
    .form-select {
        &:hover {
            cursor: pointer;
        }
    }

    input:disabled {
        background-color: map-get(base.$themes, "light-grey") !important;
    }

    .check-input-container {
        display: flex;
        align-items: center;
        line-height: 32px;
        margin-bottom: 20px;
        width: auto;

        .check-input, .check-label {
            width: fit-content;
        }

        .check-input {
            margin-right: 24px;
        }
    }

    input[type=checkbox] {
        border: 1px solid map-get(base.$themes, "light-grey");
        border-radius: 50%;
        width: 24px;
        height: 24px;
        padding: 0;

        &:checked {
            background-color: map-get(base.$themes, "green");
        }

    }



    // Validation
    .feedback {
        color: map-get(base.$themes, "red");
        padding: 10px 0;
        height: fit-content;
    }

    .icon {
        height: 32px;
        width: 32px;
        padding: 0 8px;
    }

    // Actions bar
    .actions {
        display: flex;
        justify-content: flex-end;
        width: 100%
    }
    
    // Lists 
    ul {
        margin: 0;
    }
}



.App.light, .modal.light {
    @include base.theme(base.$light-mode...);

    form {
        input {
            background-color: map-get(base.$themes, "off-white");
        }
    }

    .button {
        background-color: map-get(base.$themes, "blue");
        color: map-get(base.$themes, "off-white");

        path {
            stroke: map-get(base.$themes, "off-white");
        }

      
        &:hover {
            background-color: map-get(base.$themes, "beige");
            color: map-get(base.$themes, "blue");

            path {
                stroke: map-get(base.$themes, "blue");
            }
        }
    }
}

.App.dark, .modal.dark {
    @include base.theme(base.$dark-mode...);

    form {
        input {
            color: map-get(base.$themes, "off-white");
            background-color: map-get(base.$themes, "mid-blue");
        }
    }

    .button {
        background-color: map-get(base.$themes, "light-yellow");
        color: map-get(base.$themes, "mid-blue");

        path {
            stroke: map-get(base.$themes, "mid-blue");
        }
        &:hover {
            background-color: map-get(base.$themes, "beige");
            color: map-get(base.$themes, "blue");

            path {
                stroke: map-get(base.$themes, "blue");
            }
        }
    }


}

.modal {
    background-color: transparent !important;


    &.light {
        color: map-get(base.$themes, "soft-black");
        .modal-header, .modal-body {
            background-color: map-get(base.$themes, "beige");
        }
    }

    &.dark {
        color: map-get(base.$themes, "off-white");
        
        .modal-header, .modal-body {
            background-color: map-get(base.$themes, "blue");
        }

    }
    
}

@import "~bootstrap/scss/bootstrap";