@use "base.scss";

// light-mode mode
.light.dashboard {

    .card, .date-range > #start-date, .date-range > #end-date, .form-select{
        @include base.card(base.$light-mode-card...);
    }

    .recharts-bar-rectangle > path {
        fill: map-get(base.$themes, "blue" );
    }

    .recharts-cartesian-axis {
        line {
            stroke: map-get(base.$themes, "grey" );
        }

        text {
            fill: map-get(base.$themes, "grey" );
        }
    }
}

// dark-mode mode
.dark.dashboard {

    .card, .date-range > #start-date, .date-range > #end-date, .form-select {
        @include base.card(base.$dark-mode-card...);
    }

    .recharts-bar-rectangle > path {
        fill: map-get(base.$themes, "beige" );
    }

    .recharts-cartesian-axis {
        line {
            stroke: map-get(base.$themes, "off-white" );
        }

        text {
            fill: map-get(base.$themes, "off-white" );
        }
    }
}

.dashboard {
    padding-top: 20px;
    
    .col {
        padding-bottom: 12px;
        padding-top: 12px;
    }
    
    // Graphs
    .card {
        border: none;
        text-align: center;
        
        
        .card-title {
            padding-top: 15px;
        }
        
        .card-body {
            height: 350px;
        }
    
    }

    .recharts-tooltip-label {
        color: map-get(base.$themes, "grey");
    }

    .recharts-tooltip-item > span {
        color: map-get(base.$themes, "blue");
    }
    
    // Accordion
    .accordion {

        .header {
            display: flex;
            justify-content: space-between;
            width: 90%;
            font-weight: 800;
            letter-spacing: 1px;

            .badge {
                background-color: map-get(base.$themes, "beige") !important;
                color: map-get(base.$themes, "navy");
                border-radius: 30px;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .accordion-button {
            color: map-get(base.$themes, "white");
            font-weight: 400;
            margin-bottom: 10px;
        }
        
        .accordion-button::after {
            background-image: var(--bs-accordion-btn-icon);
        }
        
        .accordion-item {
            border: none;
        }
        
        .error > .accordion-button {
            @include base.accordion(map-get(base.$themes, "red"));      
        }
        
        .warning > .accordion-button {
            @include base.accordion(map-get(base.$themes, "yellow"));
        }
        
        .success > .accordion-button {
            @include base.accordion(map-get(base.$themes, "green"));
        }
    }
    
    // Date range buttons
    .date-range {
        text-align: center;   
        
        #start-date, #end-date {
            height: 45px;
            padding: 3px 7px 3px 7px;
            border-radius: 10px;
            outline: none;
            text-align: center;
            margin-left: 10px;
            margin-bottom: 10px;
    
            &::-webkit-calendar-picker-indicator {
                cursor: pointer;
                filter: opacity(70%);
            }
    
        }
    
    
        #start-date:hover, #end-date:hover {
            color: map-get(base.$themes, "off-white");
        }
    
    }
    
    .form-select {
        display: inline-block;
        width: 170px;
        height: 45px;
        border-radius: 10px;
        margin-left: 10px;
    }

    .div.stats.card:hover {
        background-color: none;
    }

    .recharts-rectangle.recharts-tooltip-cursor {
        fill: transparent;
    }

}
