@use "base.scss";

.delete-alert.light {
    background-color: map-get(base.$themes, "blue");
    color: map-get(base.$themes, "off-white");
}

.delete-alert.dark {
    background-color: map-get(base.$themes, "beige");
    color: map-get(base.$themes, "mid-blue");
}

.delete-alert {
    border: none;
    text-align: center;

    .actions {
        justify-content: center;
    }
}