.page.error {
    text-align: center;
    padding-top: 32px;

    h2.error-message {
        text-align: center;
        padding-top: 20px;
    }
    
    .error-icon {
        height: 64px;
        width: 64px;
    }
}
