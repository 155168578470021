@use "base.scss";

.inventory.light {
    @include base.theme(base.$light-mode...);
    
    .card-body {
        @include base.card(base.$light-mode-card...);
        background-image: linear-gradient(map-get(base.$themes, "off-white") 75%, map-get(base.$themes, "light-yellow") 50%);
    }

    .icon {
        fill: map-get(base.$themes, "blue");
    }


}

.inventory.dark {
    @include base.theme(base.$dark-mode...);
    
    .card-body, .modal-content {
        @include base.card(base.$dark-mode-card...);
        background-image: linear-gradient(map-get(base.$themes, "blue") 75%, map-get(base.$themes, "mid-blue") 50%);;
    }

    .icon {
        fill: map-get(base.$themes, "beige");
    }
}

.inventory {
    padding-top: 20px;
    font-family: "Roboto", sans-serif;
    
    .card-body {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 10px;
        height: 300px;
        text-align: center;
        position: relative;

        .card-title, .card-img  {
            text-align: center;
            margin-bottom: 10px;
            font-weight: 600;
        }

        .quantity {
            min-width: 50px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            padding: 4px;
            color: map-get(base.$themes, "off-white");
            position: absolute;
            right: -14px;
            top: -14px;
            font-weight: 600;

            &.in-stock {
                background-color: map-get(base.$themes, "green");
            }
            
            &.low-stock {
                background-color: map-get(base.$themes, "dark-yellow");
            }

            &.out-of-stock {
                background-color: map-get(base.$themes, "red");
            }

        }

        .card-details {
            text-align: left;
            padding: 12px;
            padding-top: 24px;
            display: flex;
            justify-content: space-between;


            span {
                font-weight: 600;
            }
        }
        
        .card-text {
            margin-bottom: 0;
        }

        .icon {
            height: 65%;
            width: 90%;
        }
        

    }
}