@use "base.scss";

.menu.light {
    @include base.theme(base.$light-mode...);
    
    .card-body, .modal-content {
        @include base.card(base.$light-mode-card...);
        background-image: linear-gradient(map-get(base.$themes, "off-white") 60%, map-get(base.$themes, "light-yellow") 50%);
    }

    .card-image .icon {
        fill: map-get(base.$themes, "beige");
    }

}

.menu.dark {
    @include base.theme(base.$dark-mode...);
    
    .card-body, .modal-content {
        @include base.card(base.$dark-mode-card...);
        background-image: linear-gradient(map-get(base.$themes, "blue") 60%, map-get(base.$themes, "mid-blue") 50%);;
    }

    .card-image > .icon {
        fill: map-get(base.$themes, "navy");
    }

}

.menu {
    .card-col {
        height: 50vh;
    }
    
    // Card
    .card-body {
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 12px;
        text-align: center;
        height: 95%;
        overflow: hidden;
        

        &:hover {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }

        .card-title  {
            margin-bottom: 10px;
            display: flex;
            padding: 0 10%;
            justify-content: space-between;
            font-weight: 600;
            margin-top: 16px;
            

            .title {
                text-align: left;
                max-width: 60%;
            }

            .price {
                min-width: fit-content;
            }
        }

        .card-details {
            text-align: left;
            padding: 8px 10%;
            overflow-y: auto;
            height: 22%;



            .ingredients {
                margin-top: 12px;
                font-style: italic;
                overflow: hidden;
            }

        }
        
        .card-image {
            height: 60%;
            width: 100%;
            border-radius: 12px 12px 0 0;

            .icon {
                height: 95%;
                width: 95%;
            }

        }

    }
    
    .card-body.default-cursor {
        cursor: default;
    }
}