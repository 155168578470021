@use "base.scss";
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200&display=swap');

.light.navbar {
    @include base.theme(base.$light-mode...);
    border-bottom: 1px solid map-get(base.$themes, "light-grey");

    .link.active, .link:hover {
        color: map-get(base.$themes, "blue");
    }

    .icon {
        stroke: map-get(base.$themes, "blue");
        fill: map-get(base.$themes, "blue");
    }

    @media (max-width: calc(base.$md - 1px)) {
        .navbar-collapse {
            background-color: map-get(base.$themes, "beige");
        }
    }
}


.dark.navbar {
    @include base.theme(base.$dark-mode...);
    border-bottom: 1px solid map-get(base.$themes, "mid-blue");
    
    .icon, .nav-link{
        color: map-get(base.$themes, "beige");
    }

    .link.active, .link:hover {
        color: map-get(base.$themes, "yellow");
    }

    .link.brand {
        color: map-get(base.$themes, "beige");
    }
    
    .icon, .icon:hover {
        stroke: map-get(base.$themes, "beige");
        fill: map-get(base.$themes, "beige");
    }

    @media (max-width: calc(base.$md - 1px)) {
        .navbar-collapse {
            background-color: map-get(base.$themes, "mid-blue");;
        }
    }

}

.navbar {
    padding: 0;
    background-color: map-get(base.$themes, "off-white");

    // Contianer
    .container-fluid {
        padding: 0;
    }

    // Links
    .link{
        padding: 4px;
        margin: auto;
        color: inherit;
        text-decoration: none;
        width: fit-content;

        &.active {
            text-decoration: underline 1px;
            text-underline-offset: 5px;
        }
    }

    .link:hover {
        color: map-get(base.$themes, "mid-blue");
        transition: color 0.5s;
    }

    // Brand
    .link.brand {
        padding-left: 5%;
        border: none;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        text-decoration: none;
        letter-spacing: 1.5px;
        font-size: 16px;
        font-weight: 900;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
    
        .logo {
            height: 44px;
            width: 44px;
        }
    }

    // Collapsed menu
    .navbar-toggler, .navbar-toggler:focus {
        padding-right: 5%;
        border: none;
        box-shadow: none;
        cursor: pointer;

        .icon {
            height: 32px;
            width: 32px;
            padding: 0;
        }
    }
    
    .navbar-collapse {
        text-align: center;
    }

    .navbar-nav {
        justify-content: space-between;
        width: 100%;

        a {
            margin: 6px auto;
        }
    }
    

    // Theme button
    .theme-toggle, .theme-toggle:focus {
        background-color: transparent;
        margin-bottom: 6px;
    }

    @media (min-width: base.$md) {


        .theme-toggle {
            padding-right: 64px;
        }
    }
    
    @keyframes fade-in {
        from {opacity: 0;}
        to {opacity: 1;}
    }
    
    @keyframes fade-out {
        from {opacity: 1;}
        to {opacity: 0;}
    }
    
    .theme-toggle.btn.btn-primary {
        width: 32px;
        height: 32px;
        border: none;
        border-radius: 50%;
        position: relative;
        
        
        .theme-icon {
            position: absolute;
            top: 6px;
            left: 6px;
            width: 24px;
            height: 24px;
            
            
            &.light {
                fill: map-get(base.$themes, "yellow");
                transition: 0.5s ease;
    
                &:hover {
                    fill: map-get(base.$themes , "blue");
                }
            }
    
            &.dark {
                fill: map-get(base.$themes, "white"); 
                transition: 0.5s ease;
                &:hover {
                    fill: map-get(base.$themes , "yellow");
                }
                
            }
    
            &.fade-in {
                animation: fade-in 1.5s ease;
            }
    
            &.fade-out {
                display: none;
                animation: fade-out 1.5s ease;
            }
    
        }
    }

}    

