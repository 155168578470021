@use 'base.scss';


.footer.light {
    @include base.theme(base.$light-mode...);

    .navbar-brand, .icon {
        color: map-get(base.$themes, "grey");
        color: map-get(base.$themes, "grey");
    }
}

.footer.dark {
    @include base.theme(base.$dark-mode...);

    .navbar-brand, .icon {
        color: map-get(base.$themes, "blue");
        fill: map-get(base.$themes, "blue");
    }
}

.footer {
    height: 125px;
    width: 100%;

    .navbar-brand:hover, .nav-link:hover {
        color: map-get(base.$themes, "blue");

        .icon {
            fill: map-get(base.$themes, "blue");
        }

    }


    // Links
    .links {
        padding: 0 20%;
        justify-content: center;

        .nav-link {
            width: fit-content;
            text-align: center;
        }
    }

    // Footer brand
    .navbar-brand {
        margin: 0 auto 10px auto;
        color: map-get(base.$themes, "blue");
    }

    .footer-logo {
        height: 44px;
        width: 44px;
        margin-left: 10px;
    }
}