@use "sass:map";

$sm : 576px;
$md: 767px;

$themes: (
    "navy": #172A3A,
    "white": #FFFFFF,
    "beige": #F7EDE2,
    "blue": #395c6b,
    "mid-blue": #1C495C,
    "off-white": #fbf7f5,
    "dark-grey": #212529,
    "grey": #5f5f5f,
    "light-grey": #E8E8E8,
    "soft-black": #0e1111,
    "red": #E07A5F,
    "yellow": #E4C891,
    "dark-yellow": #f1b640,
    "green": #9FB798,
    "light-yellow": #F3ECD2
);

@mixin theme ($background, $text) {
    background-color: $background;
    border: none;
    box-shadow: none;
    color: $text;
    transition: ease 0.5s;
}

@mixin card ($background, $text, $hover-color) {
    background-color: $background;
    color: $text;
    border: none;
    box-shadow: none;
    cursor: pointer;
    transition: ease 0.5s;

    &:hover {
        color: $hover-color;
        transition: color 0.5s;
    }
}

@mixin accordion ($background) {
    height: 60px;
    box-shadow: none;
    padding: 15px;
    text-align: left;
    width: 100%;
    background-color: $background;
}

$light-mode: map-get($themes, "white"), map-get($themes, "grey");

$dark-mode: map-get($themes, "navy"), map-get($themes, "off-white");

$light-mode-card: map-get($themes, "beige"), map-get($themes, "soft-black"), map-get($themes, "blue");

$dark-mode-card:  map-get($themes, "mid-blue"), map-get($themes, "white"), map-get($themes, "beige");