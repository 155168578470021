@use "base.scss";

.orders.light {
    @include base.theme(base.$light-mode...);

    .table {
        @include base.theme(base.$light-mode...);
        color:  map-get(base.$themes, "soft-black");

        .table-button, .MuiSvgIcon-root {
            color: map-get(base.$themes, "grey");
        }
    }

    .MuiDataGrid-row:hover {
        background-color: map-get(base.$themes, "beige");
        transition: background-color 0.5s;
    }

    .edit:hover {
        background-color: map-get(base.$themes, "beige");
    }
}

.orders.dark {
    @include base.theme(base.$dark-mode...);
    .table {
        @include base.theme(base.$dark-mode...);

        .table-button, .MuiSvgIcon-root, .css-rtrcn9-MuiTablePagination-root {
            color: map-get(base.$themes, "beige");
        }
    }

    .MuiDataGrid-row:hover  {
        background-color: map-get(base.$themes, "blue");
        transition: background-color 0.5s;
    }

}

.orders {
    padding-top: 20px;
    
    .actions {
        justify-content: right;
        width: 100%;
    }

    // Table
    .table-toolbar {
        justify-content: space-between;
    }
    
    .menu-items {
        width: 100%;

        .menu-item {
            display: flex;
            justify-content: space-between;
        }
    }


    .feedback {
        color: map-get(base.$themes, "red");
        padding-top: 10px;
    }

}